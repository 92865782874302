import React, { Component } from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import DashboardComponent from "../../components/DashboardComponent";

const Dashboard = (prop) => {
    return (
        <HeaderandFooter >
            <DashboardComponent />
        </HeaderandFooter>
    );
};

export default Dashboard;