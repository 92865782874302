// import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { Component, useRef, createRef } from 'react';
import { Link } from 'react-router-dom'
import { dashboard } from "../../utils/constants";
import { Dashboard } from '../../redux/Dashboard/actions';
import Spinner from "../Spinner";
import { withRouter } from 'react-router-dom';
// import { Container, Table, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import "./style.css";
import { BarChart } from '@mui/x-charts/BarChart';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import {
    ChartContainer,
    BarPlot,
    LinePlot,
    ChartsXAxis,
    ChartsYAxis,
    axisClasses,
} from '@mui/x-charts';


class DashboardComponent extends Component {
    selectedIssueType = '';
    selectedIssueStart = 0;
    selectedIssueEnd = 0;
    selectedSentTypte = 0;
    selectedSentStart = 0;
    selectedSentEnd = 0;
    issueSelectedIndex = 0;
    sentSelectedIndex = 0;

    constructor(props) {
        super(props);
        this.state = {
            con: true,
            issueXcontent: [],
            issueYcontent: [],
            sentXcontent: [],
            sentYcontent: [],
            issueTotal: '0',
            sentTotal: '0',
            revokeTotal: '0',
            VerifyRequestData: [],
            alertData: {
                status: false,
                className: '',
                message: ''
            },
            scrollPosition: 0,
        }
        this.getIssueData = this.getIssueData.bind(this);
        this.getSentData = this.getSentData.bind(this);
        this.getDownloadData = this.getDownloadData.bind(this);
        this.tableContainerRef1 = createRef(null);
        this.tableContainerRef2 = createRef(null);
    }

    GetStatisticData(type, timetype, start = "", end = "") {
        this.setState({ con: true });
        let payload = {
            type: type,
            category: "",
            timetype: timetype,
            start: start,
            end: end,
        };
        this.props.dispatch(Dashboard(dashboard, payload));
    }

    convertJSONToCSV(jsonArray) {
        const keys = Object.keys(jsonArray[0]);
        const csvRows = jsonArray.map(row => keys.map(key => row[key]).join(','));
        return `${keys.join(',')}\n${csvRows.join('\n')}`;
    }

    componentWillUnmount() {
        const tableContainer1 = this.tableContainerRef1.current;
        tableContainer1.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        const tableContainer1 = this.tableContainerRef1.current;
        const tableContainer2 = this.tableContainerRef2.current;
        tableContainer1.addEventListener('scroll', () => {
            tableContainer2.scrollTop = tableContainer1.scrollTop;
        });
        tableContainer2.addEventListener('scroll', () => {
            tableContainer1.scrollTop = tableContainer2.scrollTop;
        });

        this.selectedIssueStart = this.selectedIssueEnd = this.selectedSentStart = this.selectedSentEnd = localStorage.getItem("current_year");
        this.GetStatisticData('issue', 'month');
        this.GetStatisticData('sent', 'month');
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.StatisticStatus) {
            this.setState({ con: false });
            if (prevProps.StatisticStatus.Status === 200) {
                // this.setState({alertData:{status:'Success',className: 'success', message: prevProps.StatisticStatus.message}});
                if (prevProps.StatisticStatus.type == "download") {
                    console.log("111111111111111111111111111111111");
                    let csvString = this.convertJSONToCSV(prevProps.StatisticStatus.Data.download);
                    let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "download.csv");
                    document.body.appendChild(link); // Required for Firefox
                    link.click();
                    document.body.removeChild(link);
                }
                else {
                    if (prevProps.StatisticStatus.type == "issue") {
                        this.setState({ issueXcontent: prevProps.StatisticStatus.Data.xAxis });
                        this.setState({ issueYcontent: prevProps.StatisticStatus.Data.yAxis });
                    }
                    else if (prevProps.StatisticStatus.type == "sent") {
                        this.setState({ sentXcontent: prevProps.StatisticStatus.Data.xAxis });
                        this.setState({ sentYcontent: prevProps.StatisticStatus.Data.yAxis });
                    }
                    this.setState({ VerifyRequestData: prevProps.StatisticStatus.Data.verify });
                    this.setState({ issueTotal: prevProps.StatisticStatus.Data.issueTotal });
                    this.setState({ sentTotal: prevProps.StatisticStatus.Data.sentTotal });
                    this.setState({ revokeTotal: prevProps.StatisticStatus.Data.revokeTotal });
                }
            }
            else {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.StatisticStatus.message } });
            }
        }

    }

    getIssueData() {
        this.setState({ con: true });
        let issuetype = document.getElementById("issueTimeType").value;
        let issueStart = document.getElementById("issueStartYear").value;
        let issueEnd = document.getElementById("issueEndYear").value;
        this.selectedIssueStart = issueStart;
        this.selectedIssueEnd = issueEnd;
        this.GetStatisticData('issue', issuetype, issueStart, issueEnd);
    }

    getSentData() {
        this.setState({ con: true });
        let senttype = document.getElementById("sentTimeType").value;
        let sentStart = document.getElementById("sentStartYear").value;
        let sentEnd = document.getElementById("sentEndYear").value;
        this.selectedSentStart = sentStart;
        this.selectedSentEnd = sentEnd;
        this.GetStatisticData('sent', senttype, sentStart, sentEnd);
    }

    getDownloadData(category) {
        this.setState({ con: true });
        let payload = {
            type: "download",
            category: category,
            timetype: "",
            start: "",
            end: "",
        };
        this.props.dispatch(Dashboard(dashboard, payload));
    }

    changeIssueTime = (e) => {
        let type = e.target.options[e.target.selectedIndex].value; //document.getElementById("issueTimeType").value;
        if (type == "month") {
            document.getElementById("issueDateSlash").style.display = "none";
            document.getElementById("issueEndYear").style.display = "none";
        }
        else if (type == "year") {
            document.getElementById("issueDateSlash").style.display = "block";
            document.getElementById("issueEndYear").style.display = "block";
        }
    }

    changeSentTime = (e) => {
        let type = document.getElementById("sentTimeType").value;
        if (type == "month") {
            document.getElementById("sentDateSlash").style.display = "none";
            document.getElementById("sentEndYear").style.display = "none";
        }
        else if (type == "year") {
            document.getElementById("sentDateSlash").style.display = "block";
            document.getElementById("sentEndYear").style.display = "block";
        }
    }

    render() {
        let org_title = localStorage.getItem('organizationname');
        if (org_title != "") org_title = "(" + org_title + ")";
        let issueStartContent = [...Array(30).keys()].map(item => {
            if (this.selectedIssueStart == (item + 2018)) return (<option title={item + 2018} key={item + 1} value={item + 2018} selected={"selected"}>{item + 2018}</option>);
            else return (<option title={item + 2018} key={item + 1} value={item + 2018}>{item + 2018}</option>);
        }, this);
        let issueEndContent = [...Array(30).keys()].map(item => {
            if (this.selectedIssueEnd == (item + 2018)) return (<option title={item + 2018} key={item + 1} value={item + 2018} selected={"selected"}>{item + 2018}</option>);
            else return (<option title={item + 2018} key={item + 1} value={item + 2018}>{item + 2018}</option>);
        }, this);

        let sentStartContent = [...Array(30).keys()].map(item => {
            if (this.selectedSentStart == (item + 2018)) return (<option title={item + 2018} key={item + 1} value={item + 2018} selected={"selected"}>{item + 2018}</option>);
            else return (<option title={item + 2018} key={item + 1} value={item + 2018}>{item + 2018}</option>);
        }, this);

        let sentEndContent = [...Array(30).keys()].map(item => {
            if (this.selectedSentEnd == (item + 2018)) return (<option title={item + 2018} key={item + 1} value={item + 2018} selected={"selected"}>{item + 2018}</option>);
            else return (<option title={item + 2018} key={item + 1} value={item + 2018}>{item + 2018}</option>);
        }, this);
        // console.log(this.state.VerifyRequestData);
        let rowData = this.state.VerifyRequestData.map((item, i) => {
            return { payer: item.payer_name, id: i + 1, org: item.organization, designation: item.designation, email: item.email, 
                contactnumber: item.contactnumber, fullname: item.fullname, en_no: item.enrollnumber, progname: item.competencyname, 
                level: "UG", amountofpayment: item.amountofpayment, timeadded: item.timeadded.split(".")[0], txid: item.transactionid ? item.transactionid : "" };
        }, this);
        const columns = [
            { id: 'fullname', label: 'Full Name', minWidth: 100 },
            { id: 'id', label: 'No.', minWidth: 20 },
            { id: 'payer', label: 'Verifier', minWidth: 100 },
            { id: 'designation', label: 'Designation', minWidth: 180 },
            { id: 'org', label: 'Organization', minWidth: 180 },
            { id: 'email', label: 'Email', minWidth: 100 },
            { id: 'contactnumber', label: 'Contact', minWidth: 100 },
            { id: 'en_no', label: 'Enrollment No', minWidth: 100 },
            { id: 'progname', label: 'Programme Name', minWidth: 200 },
            { id: 'level', label: 'Level', minWidth: 100 },
            { id: 'amountofpayment', label: 'Amount', minWidth: 100 },
            { id: 'timeadded', label: 'Date', minWidth: 180 },
            { id: 'txid', label: 'Transaction ID', minWidth: 180 },
        ];

        return (
            <div className="container" style={{ maxWidth: '90%' }}>
                <center>
                    <table style={{ width: '100%', marginTop: '50px', marginBottom: '50px' }}>
                        <tbody>

                            <tr>
                                <td style={{ width: '65%', textAlign: 'center', verticalAlign: 'top' }}>

                                </td>
                                <td style={{ width: '35%', textAlign: 'left', verticalAlign: 'top' }}>
                                    <span style={{ width: '100%', fontSize: '20pt', fontWeight: 'bold', textAlign: 'left', paddingRight: '30px', textTransform: 'uppercase' }}>{localStorage.getItem('organizationname')} DASHBOARD</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '65%', textAlign: 'center', verticalAlign: 'top' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '33%', textAlign: 'center', verticalAlign: 'top' }}>
                                                    <center>
                                                        <div style={{ width: '70%', backgroundColor: '#008bb4', textAlign: 'center', display: 'inline-table', borderRadius: '24px' }}>
                                                            <p style={{ fontSize: '25pt', color: '#fff', marginTop: '24px' }}>{this.state.issueTotal}</p>
                                                            <p style={{ fontSize: '12pt', color: '#fff' }}>ISSUED CERTIFICATES</p>
                                                            <div style={{ textAlign: 'right', width: '100%', height: '36px' }} onClick={() => this.getDownloadData("issue")}>
                                                                <i className="fa fa-download" style={{ backgroundColor: 'white', color: '#008bb4', fontSize: '16px', borderRadius: '16px', marginRight: '12px', padding: '4px' }}></i>
                                                            </div>
                                                        </div>
                                                    </center>
                                                </td>
                                                <td style={{ width: '34%', textAlign: 'center', verticalAlign: 'top' }}>
                                                    <center>
                                                        <div style={{ width: '70%', backgroundColor: '#008bb4', textAlign: 'center', display: 'inline-table', borderRadius: '24px' }}>
                                                            <p style={{ fontSize: '25pt', color: '#fff', marginTop: '24px' }}>{this.state.sentTotal}</p>
                                                            <p style={{ fontSize: '12pt', color: '#fff' }}>SENT CERTIFICATES</p>
                                                            <div style={{ textAlign: 'right', width: '100%', height: '36px' }} onClick={() => this.getDownloadData("sent")}>
                                                                <i className="fa fa-download" style={{ backgroundColor: 'white', color: '#008bb4', fontSize: '16px', borderRadius: '16px', marginRight: '12px', padding: '4px' }}></i>
                                                            </div>
                                                        </div>
                                                    </center>
                                                </td>
                                                <td style={{ width: '33%', textAlign: 'center', verticalAlign: 'top' }}>
                                                    <center>
                                                        <div style={{ width: '70%', backgroundColor: '#008bb4', textAlign: 'center', display: 'inline-table', borderRadius: '24px' }}>
                                                            <p style={{ fontSize: '25pt', color: '#fff', marginTop: '24px' }}>{this.state.revokeTotal}</p>
                                                            <p style={{ fontSize: '12pt', color: '#fff' }}>REVOKED CERTIFICATES</p>
                                                            <div style={{ textAlign: 'right', width: '100%', height: '36px' }} onClick={() => this.getDownloadData("revoke")}>
                                                                <i className="fa fa-download" style={{ backgroundColor: 'white', color: '#008bb4', fontSize: '16px', borderRadius: '16px', marginRight: '12px', padding: '4px' }}></i>
                                                            </div>
                                                        </div>
                                                    </center>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <center>
                                                        <div style={{ width: '95%', marginTop: '40px' }}>
                                                            <Paper sx={{ width: '100%', overflow: 'hidden', maxWidth: 1000 }} className='d-flex'>
                                                                <TableContainer ref={this.tableContainerRef1} sx={{ maxHeight: 500, maxWidth: 160 }} className='hideScrollbar'>
                                                                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 120 }}>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell style={{ minWidth: columns[0].minWidth }}>Name</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {rowData.slice(0, 1000000).map((row) => {
                                                                                return (<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                                    <TableCell className="singleLineCell">{row.fullname}</TableCell>
                                                                                </TableRow>);
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                                <TableContainer ref={this.tableContainerRef2} sx={{ maxHeight: 500, maxWidth: 1000 }}>
                                                                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 600 }}>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {columns.slice(1).map((column) => (
                                                                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} className="singleLineCell">
                                                                                        {column.label}
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {rowData.slice(0, 1000000).map((row) => {
                                                                                return (<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                                    {columns.slice(1).map((column) => {
                                                                                        const value = row[column.id];
                                                                                        return (<TableCell key={column.id} align={column.align} className="singleLineCell">{column.format && typeof value === 'number' ? column.format(value) : value}</TableCell>);
                                                                                    })}
                                                                                </TableRow>);
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Paper>
                                                        </div>
                                                    </center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ width: '35%', textAlign: 'center', verticalAlign: 'top' }}>
                                    <div className="card shopping-cart" style={{ marginBottom: "10px" }}>
                                        <div className="card-header" style={{ backgroundColor: '#f8b101' }}>
                                            <div style={{ "marginTop": "10px", "display": "flex", justifyContent: 'space-between' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <label style={{ "marginTop": "7px", "fontSize": "14pt", color: '#ffffff' }} >ISSUED CERTIFICATES</label>
                                                </div>
                                                <div style={{ textAlign: 'right', display: 'flex' }}>
                                                    <select id='issueTimeType' style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px" }} onChange={this.changeIssueTime}>
                                                        <option title="month" key={1} value="month">Monthly</option>
                                                        <option title="year" key={2} value="year">Yearly</option>
                                                    </select>
                                                    <select id='issueStartYear' style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "40px", "fontSize": "12px" }} >
                                                        {issueStartContent}
                                                    </select>
                                                    <label id="issueDateSlash" style={{ "marginTop": "7px", "fontSize": "14pt", color: '#ffffff', marginLeft: '8px', display: 'none' }}> - </label>
                                                    <select id='issueEndYear' style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px", display: 'none' }} >
                                                        {issueEndContent}
                                                    </select>
                                                    <button style={{ marginLeft: '15px' }} onClick={() => this.getIssueData()}><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <table style={{ width: '100%' }}>
                                                <tr>
                                                    <td style={{ width: '100%', textAlign: 'center' }}>
                                                        {(this.state.issueXcontent.length > 0 && this.state.issueYcontent.length > 0) ? (
                                                            <React.StrictMode>
                                                                <StyledEngineProvider injectFirst>
                                                                    <BarChart
                                                                        xAxis={[
                                                                            {
                                                                                id: 'dateCategories',
                                                                                data: this.state.issueXcontent,
                                                                                scaleType: 'band',
                                                                            },
                                                                        ]}
                                                                        series={[
                                                                            {
                                                                                data: this.state.issueYcontent,
                                                                                // color: '#ef7f00',
                                                                                color: "#D1D8DA"
                                                                            },
                                                                        ]}
                                                                        width={600}
                                                                        height={300}
                                                                    />
                                                                </StyledEngineProvider>
                                                            </React.StrictMode>
                                                        ) : (<div style={{ "marginLeft": "0px" }} className="row"><p>There is no data</p></div>)}

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="card shopping-cart" style={{ marginBottom: "0px" }}>
                                        <div className="card-header" style={{ backgroundColor: '#005e7a' }}>
                                            <div style={{ "marginTop": "10px", "display": "flex", justifyContent: 'space-between' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <label style={{ "marginTop": "7px", "fontSize": "14pt", color: '#ffffff' }} >SENT CERTIFICATES</label>
                                                </div>
                                                <div style={{ textAlign: 'right', display: 'flex' }}>
                                                    <select id="sentTimeType" style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px" }} onChange={this.changeSentTime} >
                                                        <option title="month" key={1} value="month">Monthly</option>
                                                        <option title="year" key={2} value="year">Yearly</option>
                                                    </select>
                                                    <select id='sentStartYear' style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "40px", "fontSize": "12px" }} >
                                                        {sentStartContent}
                                                    </select>
                                                    <label id="sentDateSlash" style={{ "marginTop": "7px", "fontSize": "14pt", color: '#ffffff', marginLeft: '8px', display: 'none' }}> - </label>
                                                    <select id='sentEndYear' style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px", display: 'none' }} >
                                                        {sentEndContent}
                                                    </select>
                                                    <button style={{ marginLeft: '15px' }} onClick={() => this.getSentData()}><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <table style={{ width: '100%' }}>
                                                <tr>
                                                    <td style={{ width: '100%' }}>
                                                        {(this.state.sentXcontent.length > 0 && this.state.sentYcontent.length > 0) ? (
                                                            // <ChartContainer
                                                            //     xAxis={[
                                                            //         {
                                                            //         scaleType: 'band',
                                                            //         data: this.state.sentXcontent,
                                                            //         id: 'quarters',
                                                            //         label: 'Quarters',
                                                            //         },
                                                            //     ]}
                                                            //     yAxis={[{ id: 'quantities' }]}
                                                            //     series={[
                                                            //         {
                                                            //         type: 'bar',
                                                            //         id: 'cookies',
                                                            //         yAxisKey: 'quantities',
                                                            //         data: this.state.sentYcontent,
                                                            //         color: '#ef7f00'
                                                            //         },

                                                            //     ]}
                                                            //     width={600}
                                                            //     height={300}
                                                            //     margin={{ left: 40, right: 40, top: 40 }}
                                                            //     sx={{
                                                            //         [`.${axisClasses.left} .${axisClasses.label}`]: {
                                                            //         transform: 'rotate(-90deg) translate(0px, -20px)',
                                                            //         },
                                                            //         [`.${axisClasses.right} .${axisClasses.label}`]: {
                                                            //         transform: 'rotate(90deg) translate(0px, -25px)',
                                                            //         },
                                                            //     }}
                                                            //     >
                                                            //     <BarPlot />
                                                            //     <LinePlot />
                                                            //     <ChartsXAxis axisId="quarters" label="" labelFontSize={16} />
                                                            //     <ChartsYAxis axisId="quantities" label="" labelFontSize={16} />                                                                
                                                            // </ChartContainer>

                                                            <React.StrictMode>
                                                                <StyledEngineProvider injectFirst>
                                                                    <BarChart
                                                                        xAxis={[
                                                                            {
                                                                                id: 'dateCategories',
                                                                                data: this.state.sentXcontent,
                                                                                scaleType: 'band',
                                                                            },
                                                                        ]}
                                                                        series={[
                                                                            {
                                                                                data: this.state.sentYcontent,
                                                                                color: "#D1D8DA"
                                                                            },
                                                                        ]}
                                                                        width={600}
                                                                        height={300}
                                                                    />
                                                                </StyledEngineProvider>
                                                            </React.StrictMode>
                                                        ) : (<div style={{ "marginLeft": "0px" }} className="row"><p>There is no data</p></div>)}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>

        )
    }

}

function mapStateToProps(state, actions) {
    if (state.getDashboardData && state.getDashboardData.Data && state.getDashboardData.Data.Status) {
        return { StatisticStatus: state.getDashboardData.Data }
    }
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(DashboardComponent));

//export default DashboardComponent